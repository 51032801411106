import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const IconStyled = styled.svg`
  ${({ theme, fillColor }) =>
    fillColor &&
    `fill: ${theme.colors[fillColor] ? theme.colors[fillColor] : fillColor};`}
  ${({ height }) => height && `height: ${height}px;`}
  ${({ width }) => width && `width: ${width}px;`}
`

function IconLinkedin({ width, height, fill }) {
  return (
    <IconStyled
      viewBox="0 0 24 24"
      width={width}
      height={height}
      fillColor={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M20.447 20.452h-3.554v-5.569c0-1.328-.027-3.037-1.852-3.037-1.853 0-2.136 1.445-2.136 2.939v5.667H9.351V9h3.414v1.561h.046c.477-.9 1.637-1.85 3.37-1.85 3.601 0 4.267 2.37 4.267 5.455v6.286zM5.337 7.433a2.062 2.062 0 01-2.063-2.065 2.064 2.064 0 112.063 2.065zm1.782 13.019H3.555V9h3.564v11.452zM22.225 0H1.771C.792 0 0 .774 0 1.729v20.542C0 23.227.792 24 1.771 24h20.451C23.2 24 24 23.227 24 22.271V1.729C24 .774 23.2 0 22.222 0h.003z" />
    </IconStyled>
  )
}

IconLinkedin.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  fill: PropTypes.string,
}

IconLinkedin.defaultProps = {
  width: 32,
  height: 32,
  fill: 'black',
}

export default IconLinkedin
